<template>
  <!-- <main-wrapper :tool="false"> -->
    <div class="v-wrapper tool--none">
      <div class="v-body">
        <template>
          <div class="search-bars">
            <tool-tip :content="$t('vehicleManage.add')" placement="top" type="light-tip" :offset="-10">
              <el-button type="primary" plain @click="addHandle" v-if="loginInfo.allFuns.includes(5041)" circle v-ripple>
                <i-icon name="iconxinxiguanli_tianjia_weixuanzhong"></i-icon>
              </el-button>
            </tool-tip>
            <tool-tip :content="$t('vehicleManage.delete')" placement="top" type="light-tip" :offset="-10">
              <el-button type="primary" plain @click="deleteHandle" v-if="loginInfo.allFuns.includes(5043)" circle v-ripple>
                <i-icon name="iconshanchu"></i-icon>
              </el-button>
            </tool-tip>
            <tool-tip :content="$t('vehicleManage.export')" placement="top" type="light-tip" :offset="-10">
              <el-button type="primary" plain @click="exportHandle" :loading="exportButtonLoading" circle v-ripple>
                <i-icon name="iconxinxiguanli_daochu_weixuanzhong"></i-icon>
              </el-button>
            </tool-tip>
            <tool-tip :content="$t('vehicleManage.change')" placement="top" type="light-tip" :offset="-10">
              <el-button type="primary" plain @click="rowChange($refs.multipleTable.selection)" circle v-ripple>
                 <i-icon name="iconxinxiguanli_qianyi_weixuanzhong"></i-icon>
              </el-button>
            </tool-tip>
            <tool-tip :content="$t('vehicleManage.upload')" placement="top" type="light-tip" :offset="-10">
              <el-button type="primary" plain @click="batchImport" v-if="loginInfo.allFuns.includes(5041)" circle v-ripple>
                <i-icon name="iconxinxiguanli_daoru_weixuanzhong"></i-icon>
              </el-button>
            </tool-tip>
            <search-group :options="options" style="float:right;" @click="click" @input="({select,value,Op}) => {searchName=select;searchText=value;searchOption=Op}"
              :field="{select: searchName, value: searchText}"></search-group>
            <ul class="modeOption">
              <li @click="$emit('display-change', 1)"><i-icon name="icon-crosshairs-gps"></i-icon> {{$t('deviceManage.deviceMode')}}</li>
              <li class="active"><i-icon name="icon-car"></i-icon> {{$t('deviceManage.vehicleMode')}}</li>
            </ul>
            <el-checkbox v-model="hasChild" class="search-containChild">{{$t('vehicleManage.includeChild')}}</el-checkbox>
          </div>

          <el-table :data="tableData" border @sort-change="sortChange" @header-dragend="saveHeaderDragWidth" :height="tableHeight" class="v-table force-scroll"  size="mini" ref="multipleTable" v-loading="tableLoading" :element-loading-text="tableLoadingText"
            @expand-change="expandChange" @row-click="expandRow">

            <el-table-column type="expand" width="50">
              <div slot-scope="{row}">
                <div v-if="(!expandedRowsData[row.VehicleID] || !expandedRowsData[row.VehicleID].length) && row.DeviceCount===0" class="expand-empty">
                  <div class="expand-empty-action">
                    <el-button type="text" class="iml-1" icon="el-icon-plus" @click="addDeviceForVehicle(row)">{{$t('vehicleManage.addDevice')}}</el-button>
                    <el-button type="text" icon="el-icon-search" @click="openBindDevice(row)">{{$t('vehicleManage.searchBind')}}</el-button>
                  </div>
                  <div class="expand-empty-text">{{$t('vehicleDetail.noDeviceInfo')}}</div>
                </div>
                <p v-else-if="!expandedRowsData[row.VehicleID]" class="expand-loading" v-loading="true" element-loading-spinner="el-icon-loading"></p>
                <p v-else class="expand-details">
                  <el-button type="text" class="iml-1" icon="el-icon-plus" @click="addDeviceForVehicle(row)">{{$t('vehicleManage.addDevice')}}</el-button>
                  <el-button type="text" icon="el-icon-search" @click="openBindDevice(row)">{{$t('vehicleManage.searchBind')}}</el-button>
                  <el-table :data="expandedRowsData[row.VehicleID]" class="v-table__customStyle expandTable" style="width:100%" size="mini" @header-dragend="saveHeaderDragWidth" border>
                    <el-table-column :key="20" prop="SIM2" :label="$t('deviceManage.deviceID') + ' '" :width="TW($t('deviceManage.deviceID') + ' ', 150)">
                      <div style="white-space:nowrap;" slot-scope="{row}">
                        <img src="../../assets/icon/vip.png" class="vip" v-if="row.isVip"/>
                        <span @click.stop="openDetailDevice(row)" class="i-link">{{ row.SIM2|emptyFormat }}</span>
                      </div>
                    </el-table-column>
                    <el-table-column :key="25" prop="TrackerType" :width="TW($t('deviceManage.TrackerTypeName'), 90)" :label="$t('deviceManage.TrackerTypeName')">
                      <span slot-scope="{row}">{{ $t('dict')[row.TrackerTypeName] }}</span>
                    </el-table-column>
                    <el-table-column :key="40" prop="SIM" :label="$t('deviceManage.sim') + ' '" :width="TW($t('deviceManage.sim') + ' ', 140)">
                      <span slot-scope="{row}">

                        <span v-if="row.ExpiredDate && $i18n.locale==='zh'">
                          <img :src="getSimImg(row.ExpiredDate, row.RemainMoney)" class="simImg" @click="openSimDetail(row.SIM)"/>
                        </span>
                        <span :title="row.ObjectID"  class="sim" @click="openDetailDeviceInfo(row)">
                        {{row.SIM}}
                        </span>
                      </span>
                    </el-table-column>
                    <el-table-column :key="60" prop="PayEndDate" :label="$t('deviceManage.vip') + ' '" :width="TW($t('deviceManage.vip') + ' ', 100)">
                      <template slot-scope="{row}">
                        <p v-if="!row.PayEndDate">--</p>
                        <p v-else-if="miment(row.PayEndDate).getTime() <= miment().getTime()">{{$t('deviceManage.optionKey12')}}</p>
                        <p v-else-if="miment(row.PayEndDate).getTime() < miment().add(90, 'DD').getTime()" style="color:#f44336;">{{miment(row.PayEndDate).format("YYYY-MM-DD")}}</p>
                        <p v-else>{{miment(row.PayEndDate).format("YYYY-MM-DD")}}</p>
                      </template>
                    </el-table-column>
                    <el-table-column :key="70" prop="StatusDes" :label="$t('deviceManage.status') + ' '" :width="TW($t('deviceManage.status') + ' ', 300)">
                      <p slot-scope="{row}" class="statusdes" @click="$router.push(`/online/track?id=${row.ObjectID}`)" :title="row.StatusDes">{{row.StatusDes}}</p>
                    </el-table-column>
                    <el-table-column :key="80" prop="isOnline" :label="$t('deviceManage.online') + ' '" :width="TW($t('deviceManage.online') + ' ', 60)">
                      <template slot-scope="{row}">
                        <p class="square" :class="{isOnline: !!row.isOnline}"></p>
                      </template>
                    </el-table-column>
                    <el-table-column :key="90" :label="$t('deviceManage.acc') + ' '" :width="TW($t('deviceManage.acc') + ' ', 60)">
                      <template slot-scope="{row}">
                        <p v-if="row.Status" class="imgcolumn">
                          <span v-if="!!(row.GPSFlag%2)">
                            <span v-if="!!row.Speed"><img src="../../assets/icon/启动,行驶.gif" alt="启动,行驶" :title="$t('deviceManage.keyOnRoad')"/></span>
                            <span v-else><img src="../../assets/icon/启动,怠速.gif" alt="启动,怠速" :title="$t('deviceManage.keyDaisu')"/></span>
                          </span>
                          <span v-else><img src="../../assets/icon/启动,参考定位.gif" alt="启动,参考定位" :title="$t('deviceManage.keyRef')"/></span>
                        </p>
                        <p v-else class="imgcolumn">
                          <span v-if="!!(row.GPSFlag%2)"><img src="../../assets/icon/熄火.gif" alt="熄火" :title="$t('deviceManage.keyNoSpeed')"/></span>
                          <span v-else><img src="../../assets/icon/熄火,参考定位.gif" alt="熄火,参考定位" :title="$t('deviceManage.keyNoSpeedRef')"/></span>
                        </p>
                      </template>
                    </el-table-column>
                    <el-table-column :key="100" prop="isAlarm" :label="$t('deviceManage.alarm') + ' '" :width="TW($t('deviceManage.alarm') + ' ', 60)">
                      <template slot-scope="{row}">
                        <p v-if="!!row.isAlarm" class="imgcolumn"><img src="../../assets/icon/报警.gif" alt="报警" :title="$t('deviceManage.keyAlarm')"/></p>
                        <p v-else class="imgcolumn"><img src="../../assets/icon/正常.gif" alt="正常" :title="$t('deviceManage.keyNormarl')"/></p>
                      </template>
                    </el-table-column>
                    <el-table-column :key="105" prop="RcvTime" :label="$t('deviceManage.gpsTime') + ' '" :width="TW($t('deviceManage.gpsTime') + ' ', 150)">
                      <p slot-scope="{row}">{{row.GPSTime ? miment(row.GPSTime).format() : ''}}</p>
                    </el-table-column>
                    <el-table-column :key="110" prop="RcvTime" :label="$t('deviceManage.rcvTime') + ' '" :width="TW($t('deviceManage.rcvTime') + ' ', 150)">
                      <p slot-scope="{row}">{{row.RcvTime ? miment(row.RcvTime).format() : ''}}</p>
                    </el-table-column>
                    <el-table-column :key="120" prop="OfflineDesc" :label="$t('deviceManage.offlineTime') + ' '" :width="TW($t('deviceManage.offlineTime') + ' ', 120)"></el-table-column>
                    <el-table-column :key="130" :label="$t('deviceManage.place') + ' '" :min-width="180">
                      <template slot-scope="{row}">
                        <span-address :lon="row.Lon" :lat="row.Lat"></span-address>
                      </template>
                    </el-table-column>
                    <el-table-column :key="160" label="" :resizable="false" min-width="95px">
                      <template slot-scope="{row}">
                        <i-drop-down trigger="click">
                          <span slot="active" class="i-link">{{$t('common.operate')}}<i class="el-icon-caret-bottom" style="margin-left:0.5em;"></i></span>
                          <i-drop-down-option :label="$t('common.modify')" value="0" @click="openDetailDevice(row)"></i-drop-down-option>
                          <i-drop-down-option :label="$t('vehicleDetail.actionUnBind')" value="3" @click="unbindDevice(row)"></i-drop-down-option>
                          <i-drop-down-option :label="$t('deviceManage.updateVip')" value="3" @click="singlePay(row)" v-if="loginInfo.allFuns.includes(20141)"></i-drop-down-option>
                          <i-drop-down-option :label="$t('common.delete')" value="4" @click="deleteDeviceHandle([row])" v-if="loginInfo.allFuns.includes(5043)"></i-drop-down-option>
                        </i-drop-down>
                      </template>
                    </el-table-column>
                  </el-table>
                </p>
              </div>
            </el-table-column>
            <el-table-column type="selection" width="40" :selectable="selectableHandle"></el-table-column>
            <el-table-column sortable="custom" prop="VehicleName" class-name="vehicle-name" :label="$t('vehicleManage.vehicleName')" :width="TW($t('vehicleManage.vehicleName'), 120)" show-overflow-tooltip>
              <!-- <div class="i-link vehicleName" style="overflow:hidden;" slot-scope="{row}" :class="getVehicleBackColor(row.VehicleNumBackColor)" @click.stop="rowClick(row)" :title="`${row.VehicleName}--id:${row.VehicleID}`">{{ row.VehicleName }}</div> -->
              <vehicle-name slot-scope="{row}" :color="row.VehicleNumBackColor" @click.stop="rowClick(row)" :data-id="row.VehicleID" >{{ row.VehicleName }}</vehicle-name>
              <!-- <vehicle-popover :item-id="row.VehicleID" slot-scope="{row}">
                <vehicle-name :color="row.VehicleNumBackColor" @click.stop="rowClick(row)" :data-id="row.VehicleID" >{{ row.VehicleName }}</vehicle-name>
              </vehicle-popover> -->
            </el-table-column>
            <el-table-column sortable="custom" prop="PeopleName" :label="$t('vehicleManage.vehiclePeople')" :width="TW($t('vehicleManage.vehiclePeople'), 120)"></el-table-column>
            <el-table-column sortable="custom" prop="VehicleBrandName" :label="$t('vehicleManage.vehicleBrand')" :width="TW($t('vehicleManage.vehicleBrand'), 120)">
              <el-row slot-scope="{row}" :title="row.VehicleID" align="middle" type="flex">
                <img :src="`${DOWNLOAD_URL}/static/brand/${row.VehicleBrandName}.jpg`" style="height:21px;margin-right:8px;" alt="">
                {{$t('dict')[row.VehicleBrandName] || row.VehicleBrandName}}
              </el-row>
            </el-table-column>
            <el-table-column sortable="custom" prop="ItemName" :label="$t('vehicleManage.vehicleType')" :width="TW($t('vehicleManage.vehicleType'), 95)">
              <el-row slot-scope="{row}" :title="row.VehicleID" align="middle" type="flex">
                <img :src="`${DOWNLOAD_URL}/static/car-type/${row.VehicleTypeName}.gif`" style="height:21px;margin-right:8px;" alt="">
                {{$t('dict')[row.VehicleTypeName] || row.VehicleTypeName}}
              </el-row>
            </el-table-column>
            <el-table-column sortable="custom" prop="DeviceCount" :label="$t('vehicleManage.deviceCount')" :width="TW($t('vehicleManage.deviceCount'), 100)">
              <div style="overflow:hidden;" slot-scope="{row}">{{ row.DeviceCount }}</div>
            </el-table-column>
            <el-table-column sortable="custom" prop="CreateTime" :label="$t('vehicleManage.createTime')" :width="TW($t('vehicleManage.createTime'), 180)">
              <p slot-scope="{row}">{{miment(row.CreateTime).format()}}</p>
            </el-table-column>
            <el-table-column sortable="custom" prop="HoldName" :label="$t('vehicleManage.enterprise')"></el-table-column>
            <el-table-column prop="Remark" :label="$t('vehicleManage.remark')" :width="TW($t('vehicleManage.remark'), 280)" show-overflow-tooltip></el-table-column>
            <el-table-column label="" :resizable="false" min-width="95px">
              <template slot-scope="{row}">
                <i-drop-down trigger="click">
                  <span slot="active" class="i-link">{{$t('common.operate')}}<i class="el-icon-caret-bottom" style="margin-left:0.5em;"></i></span>
                  <i-drop-down-option :label="$t('deviceManage.place')" value="5" @click="goMonitor(row)" ></i-drop-down-option>
                  <i-drop-down-option :label="$t('common.modify')" v-if="loginInfo.allFuns.includes(5042)" value="0" @click="rowClick(row)"></i-drop-down-option>
                  <i-drop-down-option :label="$t('common.bind')" value="1" @click="rowClick(row, 'vehicle')"></i-drop-down-option>
                  <i-drop-down-option :label="$t('common.migrate')" v-if="loginInfo.allFuns.includes(5042)" value="3" @click="rowChange([row])"></i-drop-down-option>
                  <i-drop-down-option :label="$t('common.delete')" value="4" @click="deleteHandle([row])" v-if="loginInfo.allFuns.includes(5043)"></i-drop-down-option>
                </i-drop-down>
              </template>
            </el-table-column>
          </el-table>

          <el-pagination
            background
            class="v-pager"
            :page-size="pageSize"
            :page-sizes="pageSizes"
            :pager-count="11"
            :current-page="pageIndex"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageTotalCount"
            @current-change="pageCurrentChange"
            @size-change="pageSizeChange"
            popper-class="custom">
          </el-pagination>

          <transition name="scale-grow" @before-enter="isAnimating=true" @before-leave="isAnimating=true" @after-enter="isAnimating=false" @after-leave="isAnimating=false">
            <keep-alive :include="keepAliveList">
              <router-view @refresh="refreshHandle"></router-view>
            </keep-alive>
          </transition>

          <el-dialog :title="$t('vehicleDetail.moveVehicle')" custom-class="v-dialog v-dialog--sm v-dialog--mdpadding" :visible.sync="dialogChangeParent" @closed="dialogChangeParnetReal=false" v-if="dialogChangeParnetReal">
            <change-parent :datas="changedRow" v-if="dialogChangeParent" :old-hold-info="{id:currentHoldId,name:currentHoldName}" @close="dialogChangeParent=false"></change-parent>
          </el-dialog>

          <detail-vehicle ref="detail" @refresh="refreshHandle"></detail-vehicle>
          <!-- <div class="page__detail">
            测试页面
          </div> -->
          <import-tool ref="importTool" :download-url="$t('batchImport.fileName1')"></import-tool>
          <import-detail :items="excelItems" ref="importDetail" :download-method="excelDownLoadMethod"></import-detail>
          <detail-device ref="detailDevice" @refresh="refreshExpanded"></detail-device>
          <detail-device-info ref="detailDeviceInfo"></detail-device-info>

          <el-dialog :custom-class="iframe.class" :visible.sync="isShowDialog" top="5vh" :width="iframe.width" :title="iframe.title" @closed="closedIframe" append-to-body v-if="isShowDialogReal">
            <iframe frameborder="0" class="mine" :height="iframe.height" width="100%" scrolling="yes" :src="iframe.src"></iframe>
          </el-dialog>

          <el-dialog :title="$t('wallet.wallet')" append-to-body width="400px" custom-class="v-dialog v-dialog--wallet" :visible.sync="mixinDialogWalletVisible" v-if="mixinDialogWalletVisibleReal" @closed="mixinDialogWalletClosed">
            <wallet-info @close="() => mixinDialogWalletVisible = false" @send="mixinSend"></wallet-info>
          </el-dialog>
          <el-dialog :title="$t('wallet.sendVPoint')" append-to-body custom-class="v-dialog v-dialog--sm v-dialog--mdpadding" :visible.sync="mixinDialogWalletSendVisible" v-if="mixinDialogWalletSendVisibleReal" @closed="mixinDialogWalletClosed">
            <wallet-send @close="() => mixinDialogWalletSendVisible = false"></wallet-send>
          </el-dialog>
          <el-dialog width="400px" :title="$t('deviceManage.updateVip')" :visible.sync="dialogSinglePay" custom-class="v-dialog v-dialog--wallet" top="5vh" v-if="dialogSinglePayReal" @closed="dialogSinglePayReal=false">
            <single-pay v-bind="single" @close="singlePayClose" @go-wallet="goWallet"></single-pay>
          </el-dialog>

          <el-dialog :title="`${$t('vehicleDetail.bindDevice')}:${tempRow.VehicleName}`" :visible.sync="dialogBind" width="70%" :append-to-body="true" :close-on-click-modal="false" v-if="dialogBindReal" @closed="dialogBindReal=false">
            <device-bind v-model="dialogBindList"></device-bind>
            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogBind = false" class="customStyle" type="text" size="medium">{{$t('common.cancel')}}</el-button>
              <el-button type="primary" class="customStyle border" plain @click="bingDeviceForVehicle" size="medium">{{$t('common.save')}}</el-button>
            </span>
          </el-dialog>
        </template>
      </div>
    </div>
  <!-- </main-wrapper> -->
</template>

<script>
import { VclCode } from 'vue-content-loading'
import SearchGroup from './components/search-group'
import IDropDown from '@/base/i-dropdown/i-dropdown'
import IDropDownOption from '@/base/i-dropdown/i-dropdown-option'
import mixin from './mixin'
import * as API from '@/api/vehicle'
import {Filter, Op, DOWNLOAD_URL} from '@/api/config'
import TreeUser from '@/views/common/tree-user'
import DetailVehicle from './detail/detail-vehicle'
import ImportTool from './components/import-tool'
import ImportDetail from './components/import-detail'
import { saveBlob } from '@/common/utils'
import miment from 'miment'
import VehiclePopover from '@/views/common/vehicle-popover'
import {Query as QueryDevice, Delete as DeleteDevice, UnBind, Bind as BindAPI} from '@/api/device'
import DetailDevice from './detail/detail-device'
import DetailDeviceInfo from './detail/detail-device-info'
import {GetSimInfoUrl} from '@/api/common'
import mixinWallet from '@/views/mixins/mixin-wallet'
import SinglePay from '@/views/wallet/single-pay'
import DeviceBind from '@/views/manage/device-bind'
import {GetObjectIdByVehicleID} from '@/api/common'
export default {
  name: 'manageVehicle',
  data() {
    return {
      DOWNLOAD_URL,
      options: [
        { label: this.$t('vehicleManage.vehicleName'), value: 'VehicleName', Op: Op.Contains },
        { label: this.$t('deviceManage.deviceID'), value: 'O.SIM2', Op: Op.Contains },
        { label: this.$t('deviceManage.sim'), value: 'O.SIM', Op: Op.Contains },
        { label: this.$t('vehicleManage.vehiclePeople'), value: 'PeopleName', Op: Op.Contains },
        //{ label: this.$t('vehicleManage.deviceCount'), value: 'ItemName', Op: Op.Contains },
        { label: this.$t('vehicleManage.remark'), value: 'V.Remark', Op: Op.Contains },
      ],
      isAnimating: false, // 是否正在动画
      excelItems: [
        { label: '序号', prop: 'Index' },
        { label: '车牌', prop: 'VehicleName' },
        { label: '车辆类型', prop: 'VehicleTypeName' },
        { label: '备注', prop: 'Remark' },
        { label: '导入结果', prop: 'errDesc' },
        { label: '导入详情', prop: 'errMsg' },
      ],
      miment,
      excelDownLoadMethod: null,
      expandedRowsData: {},
      openedDeviceVehicleID: null,
      isShowDialog: false,
      isShowDialogReal: false,
      iframe: {
        title: '',
        class: '',
        width: '920px',
        height: '700px',
        src: '#'
      },

      dialogSinglePay: false,
      dialogSinglePayReal: false,
      single: {
        id: null,
        vehicleName: null,
        serviceEndDate: null
      },
      dialogBindList: [],
      dialogBind: false,
      dialogBindReal: false,
      tempRow: {}
    }
  },
  components: {
    IDropDown,
    IDropDownOption,
    SearchGroup,
    SinglePay,
    VclCode,
    TreeUser,
    DetailVehicle,
    ImportDetail,
    ImportTool,
    VehiclePopover,
    DeviceBind,
    DetailDevice,
    DetailDeviceInfo
  },
  computed:{
    keepAliveList() {
      const arr = this.$route.path == '/manage/vehicle' ? null : 'detail-vehicle'
      return arr
    }
  },
  mixins: [
    mixin,
    mixinWallet
  ],
  watch:{
    hasChild() {
      this.queryHandle()
    },
    '$i18n.locale': 'getOptions'
  },
  created() {
    // this.searchName = this.options[0].value
    this.pageIndex = 1
    this.queryHandle()
  },
  methods: {
    click() {
      this.pageIndex = 1
      this.queryHandle()
    },
    getOptions() {
      this.options = [
        { label: this.$t('vehicleManage.vehicleName'), value: 'VehicleName', Op: Op.Contains },
        { label: this.$t('deviceManage.deviceID'), value: 'O.SIM2', Op: Op.Contains },
        { label: this.$t('deviceManage.sim'), value: 'O.SIM', Op: Op.Contains },
        { label: this.$t('vehicleManage.vehiclePeople'), value: 'PeopleName', Op: Op.Contains },
        //{ label: this.$t('vehicleManage.deviceCount'), value: 'ItemName', Op: Op.Contains },
        { label: this.$t('vehicleManage.remark'), value: 'V.Remark', Op: Op.Contains }
      ]
    },
    rowClick(row, panelName) {
      // if (scrollToEnd) {
      //   this.$router.push(`/manage/vehicle/detail?id=${row.VehicleID}&t=bind`)
      // } else {
      //   this.$router.push(`/manage/vehicle/detail?id=${row.VehicleID}`)
      // }

      this.$refs.detail.open(row.VehicleID, {tabName: panelName})
    },
    selectableHandle() {
      return true
      //return row.UserID != this.loginInfo.UserID
    },
    addHandle() {
      // this.$router.push('/manage/vehicle/detail')
      this.$refs.detail.open(null, {})
    },
    deleteHandle(rows) {
      if (!(rows instanceof Array)) {
        rows = null
      }

      const targetRows = rows || this.$refs.multipleTable.selection

      if (targetRows.length <= 0) {
        this.$alert(this.$t('common.emptySelectedTip'), '', {
          type: 'error'
        })
        return
      }

      let msg0 = this.$t('common.deleteTip')
      this.$confirm(msg0, this.$t('common.warn'), {
        confirmButtonText: this.$t('common.confirm'),
        cancelButtonText: this.$t('common.cancel'),
        type: 'warning'
      }).then(() => {

        this.deleteButtonLoading = true
        API.Delete(targetRows.map(k => k.VehicleID)).then(ret => {
          // 从本地删除
          // targetRows.forEach(item => {
          //   deleteFromArray(this.tableData, row => {
          //     return row == item
          //   })
          // })
          if (ret.data.errCode) {
            this.$message({
              type: 'error',
              message: ret.data.errMsg,
              showClose: true
            })
            return
          }

          this.$message({
            type: 'success',
            message: this.$t('common.deleteSuccessed'),
            showClose: true
          });

          if ((targetRows.length < this.pageSize)
            && (targetRows.length < this.tableData.length)) {
            this.pageIndex = this.pageIndex > 1 ? this.pageIndex - 1 : 1
          }
          this.pageIndex = 1
          this.queryHandle()

        }).catch(err => {
          this.print('error', err.message)
        })

      }).catch(() => {

      });
    },
    exportHandle() {
      this.exportButtonLoading = true
      API.Export(this.currentHoldId, this.hasChild).then(ret => {
        this.exportButtonLoading = false
        saveBlob(ret.data, `${this.$t('vehicleDetail.name')}.xlsx`)
      })
    },
    batchImport() {
      this.excelDownLoadMethod = API.DownLoadImportVehicleDetail
      this.$refs.importTool.import()
        .then(fileName => {
          this.$refs.importDetail.open(fileName)
          return API.Import(fileName, this.currentHoldId)
        }).then(ret => {
          console.log(ret.data)
          this.$refs.importDetail.setData(ret.data.list)
        })
    },
    queryHandle() {
      const promise = new Promise((resolve, reject) => {
        const filters = []

        this.searchText && this.searchText.length > 0
          && (filters.push(new Filter(this.searchName, this.searchText, this.searchOption)))
        this.tableLoading = true
        API.Query(this.pageIndex, this.pageSize, this.sortKey, this.sortDirect, filters, this.currentHoldId, this.hasChild).then(ret => {
          this.deleteKeys()
          this.tableData = ret.data.list.slice()
          this.pageTotalCount = ret.data.total
          this.tableLoading = false
          resolve()
        }).catch(err => {
          this.print('error', err.message)
          this.tableLoading = false
          reject(err)
        })
      })
      return promise
    },
    refreshHandle() {
      this.pageIndex = 1
      this.queryHandle()
    },
    refreshExpanded() {
      if (!this.openedDeviceVehicleID) {
        throw new Error('没有展开的车辆详情')
      }
      // 获取数据
      const filters = []
      filters.push(new Filter('D.VehicleID', this.openedDeviceVehicleID, Op.Equals))
      QueryDevice(1, 100, '', '', filters, this.loginInfo.HoldID, true).then(ret => {
        if (this.expandedRowsData[this.openedDeviceVehicleID]) {
          this.expandedRowsData[this.openedDeviceVehicleID] = ret.data.list
        } else {
          this.$set(this.expandedRowsData, this.openedDeviceVehicleID, ret.data.list.slice())
        }
      })
    },
    getVehicleBackColor(name) {
      return {
        '蓝色': 'blue',
        '绿色': 'green',
        '黄色': 'yellow',
        '白色': 'white',
        '黑色': 'black'
      }[name] || 'blue'
    },
    deleteKeys() {
      for (let key in this.expandedRowsData) {
        this.$delete(this.expandedRowsData, key)
      }
    },
    getSimImg(date, money) {
      if (!this.img_sim1) {
        this.img_sim1 = require('../../assets/icon/sim1.gif')
        this.img_sim2 = require('../../assets/icon/sim2.gif')
        this.img_sim3 = require('../../assets/icon/sim3.gif')
      }
      let days = Math.floor((-miment().diff(date))/1000/3600/24)
      if (days > 60) {
        if (days < 1800) {
          return this.img_sim1
        } else {
          if (money == 0) {
            return this.img_sim3
          } else if (money < 5) {
              return this.img_sim2
          } else {
            return this.img_sim1
          }
        }
      } else {
        if (days > -1) {
          return this.img_sim2
        } else {
          return this.img_sim3
        }
      }
    },
    expandChange(row, expandedRows) {
      // this.tableData.forEach(k => {
      //   this.$refs.multipleTable.toggleRowExpansion(k, false)
      // })
      if (expandedRows.includes(row)) {
        // 展开行时关闭其他行
        expandedRows.filter(k => k != row).forEach(k => {
          this.$refs.multipleTable.toggleRowExpansion(k, false)
        })
      }

      if (row.DeviceCount === 0) {
        return
      }
      if (this.expandedRowsData[row.VehicleID]) {
        return
      }
      // 获取数据
      const filters = []
      filters.push(new Filter('D.VehicleID', row.VehicleID, Op.Equals))
      QueryDevice(1, 100, '', '', filters, this.loginInfo.HoldID, true).then(ret => {
        this.$set(this.expandedRowsData, row.VehicleID, ret.data.list.slice())
      })
    },
    expandRow(row) {
      this.$refs.multipleTable.toggleRowExpansion(row)
    },
    // 查看设备详情
    openDetailDevice(row, VehicleID = null) {
      // 设备ID
      const ObjectID = (row && row.ObjectID) || 0

      if (ObjectID === 0 && VehicleID === null) {
        throw new Error('openDetailDevice输入的参数不正确')
      }

      // 打开详情
      this.$refs.detailDevice.open(ObjectID)

      // 点击设备的车辆ID
      this.openedDeviceVehicleID = (row && row.VehicleID) || VehicleID
    },
    openDetailDeviceInfo(row) {
      this.$refs.detailDeviceInfo.open(row.ObjectID)
    },
    openIframe(options) {
      this.iframe = Object.assign(this.iframe, options)
      this.isShowDialog = true
      this.isShowDialogReal = true
    },
    closedIframe() {
      this.iframe.src = "about:blank";
      this.isShowDialogReal = false
    },
    openSimDetail(sim) {
      GetSimInfoUrl(sim).then(ret => {
        if (!ret.data.errCode) {
          console.log(ret.data)
          this.openIframe({
            width: '800px',
            height: '595px',
            class: 'el-dialog--p0',
            src: ret.data.url,
            title: `${this.$t('deviceManage.simInfo')}`
          })
        } else {
          this.$message({
            message: ret.data.errMsg,
            type: 'error'
          })
        }
      })
    },
    deleteDeviceHandle(rows) {
      if (!(rows instanceof Array)) {
        rows = null
      }

      const targetRows = rows // || this.$refs.multipleTable.selection

      if (targetRows.length <= 0) {
        this.$alert(this.$t('common.emptySelectedTip'), '', {
          type: 'error'
        })
        return
      }

      let msg0 = this.$t('common.deleteTip')
      this.$confirm(msg0, this.$t('common.warn'), {
        confirmButtonText: this.$t('common.confirm'),
        cancelButtonText: this.$t('common.cancel'),
        type: 'warning'
      }).then(() => {

        this.deleteButtonLoading = true
        DeleteDevice(targetRows.map(k => k.ObjectID)).then(ret => {
          // 从本地删除
          // targetRows.forEach(item => {
          //   deleteFromArray(this.tableData, row => {
          //     return row == item
          //   })
          // })
          if (ret.data.errCode) {
            this.$message({
              type: 'error',
              message: ret.data.errMsg,
              showClose: true
            })
            return
          }

          this.$message({
            type: 'success',
            message: this.$t('common.deleteSuccessed'),
            showClose: true
          });

          if ((targetRows.length < this.pageSize)
            && (targetRows.length < this.tableData.length)) {
            this.pageIndex = this.pageIndex > 1 ? this.pageIndex - 1 : 1
          }

          this.queryHandle()
        }).catch(err => {
          this.print('error', err.message)
        })

      }).catch(() => {

      });
    },
    // 解绑设备
    unbindDevice(row) {
      UnBind([row.ObjectID]).then(() => {
        this.openedDeviceVehicleID = row.VehicleID
        this.refreshExpanded()
        this.$message({
          type: 'success',
          message: this.$t('vehicleManage.removeSuccess'),
          showClose: true
        });
      })
    },
    goWallet() {
      this.dialogSinglePay = false
      this.mixinOpenWallet()
    },
    singlePay(item) {
      this.single.id = item.ObjectID
      this.single.serviceEndDate = item.PayEndDate
      this.single.vehicleName = item.SIM2
      this.single.trackerType = item.TrackerType
      this.dialogSinglePay = true
      this.dialogSinglePayReal = true
      this.openedDeviceVehicleID = item.VehicleID
    },
    singlePayClose() {
      this.dialogSinglePay = false
      this.refreshExpanded()
    },
    addDeviceForVehicle(row) {
      this.$refs.detailDevice.open(null, {hideVehicle: true, vid: row.VehicleID})
      this.openedDeviceVehicleID = row.VehicleID
    },
    openBindDevice(row) {
      this.dialogBindList.splice(0)
      this.dialogBind = true
      this.dialogBindReal = true
      this.tempRow = row
    },
    bingDeviceForVehicle() {
      BindAPI(this.dialogBindList.map(k => k.ObjectID), this.tempRow.VehicleID).then(() => {
        this.openedDeviceVehicleID = this.tempRow.VehicleID
        this.dialogBind = false
        this.refreshExpanded()
      })
    },
    goMonitor(row) {
      GetObjectIdByVehicleID(row.VehicleID).then(ret => {
        if (ret.data.data.length == 0) {
          return this.$message(this.$t('vehicleManage.nodevice'))
        }
        this.$router.push(`/online/monitor?action=addTaskList&objectid=${ret.data.data.join(',')}`)
      })
    }
  },
  activated() {
    if (this.currentHoldId_bak != this.currentHoldId) {
      this.currentHoldId_bak = this.currentHoldId
      this.queryHandle()
    } else if (this.hasChild !== this.currentHasChild) {
      this.hasChild = this.currentHasChild
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../styles/variables-simple.scss";
.v-wrapper {
  display: flex;
  align-items: stretch;
  padding: 8px;
  .v-nav {
    width: 250px;
    flex-shrink: 0;
    overflow-y: auto;
    position: relative;
  }
  .v-body {
    width: 0;
    flex-grow: 1;
  }
}
.vehicleName {
  display: inline-block;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 2px;
  &.blue {
    background-color: #1976D2 !important;
    color: #FFF !important;
  }
  &.green {
    background-color: #81C784 !important;
    color: #FFF !important;
  }
  &.yellow {
    background-color: #FBC02D !important;
    color: #000 !important;
  }
  &.white {
    background-color: #FAFAFA !important;
    color: #000 !important;
  }
  &.black {
    background-color: #000 !important;
    color: #FFF !important;
  }
}

.expand-empty {
  border: 1px solid #ebeef5;
  position: relative;
  .expand-empty-text {
    text-align: center;
    color: rgba(0, 0, 0, .26);
    height: 64px;
    line-height: 64px;
  }
  .expand-empty-action {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.expand-loading {
  height: 32px;
  line-height: 32px;
  & ::v-deep .el-loading-spinner {
    margin-top: -16px;
  }
}

.expand-details {
  // border: 1px solid rgba(204, 51, 51, .10);
  border: 1px solid #ebeef5;
}

.vip {
  height: 15px;
  vertical-align: sub;
  margin: 0 4px 0 4px;
}

.statusdes {
  //overflow: hidden;
  //white-space: nowrap;
  //text-overflow: ellipsis;
  &:hover {
    cursor: pointer;
    opacity: .84;
    color: $--color-primary;
  }
}

.sim {
  &:hover {
    cursor: pointer;
    opacity: .84;
    color: $--color-primary;
  }
}

.simImg {
  vertical-align: sub;
  margin-right: 2px;
  &:hover {
    cursor: pointer;
  }
}

.square {
  height: 14px;
  width: 14px;
  display: block;
  border-radius: 2px;
  background-color: #BDBDBD;
  &.isOnline {
    background-color: #4CAF50;
  }
}

.expandTable {
  // $border-color: rgba(204, 51, 51, .26);
  //border-color: $border-color;
  &::before {
    background-color: transparent;
  }
  &::after {
    background-color: transparent;
  }
  color: rgba(0, 0, 0, .54);
  & ::v-deep {
    .el-table__header-wrapper {
      table > thead > tr > th {
        padding: 6px 0;
        color: rgba(0, 0, 0, .54);
        border-right-color: #ebeef5 !important;
      }
    }
    .el-table__body-wrapper {

    }
  }
  & ::v-deep .cell {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
}

.imgcolumn img {
  vertical-align: text-top;
  margin-top: -1px;
}

.v-table.force-scroll {
  & ::v-deep {
    .el-table__header-wrapper {
      thead tr:first-child th.gutter {
        //width: 17px !important;
        display: table-cell !important;
      }
    }
    .el-table__body-wrapper {
      //overflow-y: scroll !important;
    }
  }
}

.modeOption {
  margin: 0 0 0 24px;
  display: inline-block;
  list-style: none;
  font-size: 12px;
  li {
    display: inline-block;
    padding: 9px 15px;
    border: 1px solid #E4E7ED;
    border-right: none;
    &:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    &:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border-right: 1px solid #E4E7ED;
    }
    &:hover {
      color: $--color-primary;
      cursor: pointer;
    }
    &.active {
      cursor: default;
      background-color: $--color-primary;
      color: #FFF;
      border-color: $--color-primary;
    }
    .icon {
      font-size: 14px;
      margin: -1px 2px -1px 0;
    }
  }
}
</style>
